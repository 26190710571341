import * as microsoftTeams from '@microsoft/teams-js';
import { SigninProvider } from '@outmind/types';
import React, { memo, useEffect, useState } from 'react';
import { focusManager } from 'react-query';

import outmindLogo from '../../assets/outmind-logo.svg';
import {
  useLinkHandler,
  useRuntimeEnv,
  useSetDesktopSignInToken,
  useSignIn,
  useTranslations,
  useUserProfile,
} from '../../hooks';
import { Button } from '../../material';
import { SignInButtons, WelcomeView } from '..';
import { useStyles } from './styles';

const { REACT_APP_WEBAPP_URL: WEBAPP_URL } = process.env;

const SignInClassicViewNP: React.FC<{ inSpotlight?: boolean }> = ({ inSpotlight }) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const [idToken, setIdToken] = useState<string>();
  const [provider, setProvider] = useState<SigninProvider>();

  const { inMicrosoftTeams, inElectron } = useRuntimeEnv();

  const { mutate: signIn, isError } = useSignIn();

  const { refetch: refetchUserProfile, isSuccess: isUserLoggedIn } = useUserProfile();

  if (inMicrosoftTeams) {
    microsoftTeams.authentication.getAuthToken({
      failureCallback: (error) => {
        console.log(`Could not login in teams: ${error}`);
      },
      successCallback: (result) => {
        setIdToken(result);
        setProvider('teams-sso');
      },
    });
  }

  const signInWebApp = useLinkHandler(
    `${WEBAPP_URL}/desktop-signin?uuid=${window.electron?.getSignInUUID()}`,
  );

  const { isSuccess } = useSetDesktopSignInToken();

  const onWindowFocus = (): void => {
    if (isUserLoggedIn) {
      window.location.reload();
    } else {
      refetchUserProfile();
    }
  };

  const onWindowBlur = (): void => {
    focusManager.setFocused(true);
  };

  useEffect(() => {
    window.addEventListener('focus', onWindowFocus);
    window.addEventListener('blur', onWindowBlur);

    return () => {
      window.removeEventListener('focus', onWindowFocus);
      window.removeEventListener('blur', onWindowBlur);
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      refetchUserProfile();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (idToken && provider) signIn({ idToken, provider });
  }, [idToken, provider, signIn]);

  const singinView = (
    <>
      <div className={classes.signinTitle}>{t('welcome_to')}</div>
      <div className={classes.imageLogoContainer}>
        <img alt="outmind-logo" src={outmindLogo} />
      </div>

      {!inElectron ? (
        <>
          <div className={classes.signinHelper}>{t('signin_helper')}</div>
          <SignInButtons
            isError={isError}
            onSuccess={(_provider, token) => {
              setProvider(_provider);
              setIdToken(token);
            }}
            variant="large"
          />
        </>
      ) : (
        <div>
          <Button className={classes.desktopSignInButton} onClick={signInWebApp} variant="outlined">
            {t('click_here_to_connect')}
          </Button>
        </div>
      )}
    </>
  );
  if (inSpotlight) {
    return (
      <div className={classes.spotlightViewContainer}>
        <div className={classes.spotlightView}>{singinView}</div>
      </div>
    );
  }

  return <WelcomeView withFooter>{singinView}</WelcomeView>;
};

export const SignInClassicView = memo(SignInClassicViewNP);
